
import { inject } from '@/inversify';
import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';

import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MonthPicker from '@/modules/common/components/ui-kit/month-picker.vue';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import { DATA_TYPE } from '@/modules/scheduled-reports/constants';
import { RatesClusterExcelForm } from '../interfaces/rates-cluster-excel-form.interface';
import ClusterRatesService, { ClusterRatesServiceS } from '../cluster-rates.service';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        DownloadReportForm,
    },
})
export default class RatesDownloadExcelPopup extends Mixins(DownloadReportControlMixin) {
    @inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    form: RatesClusterExcelForm = {} as RatesClusterExcelForm;
    private isLoading = false;

    get attrs() {
        const { filters, customColumns } = this;
        const { properties, buttons } = this;
        const { isLoading } = this;

        return {
            filters,
            customColumns,
            properties,
            buttons,
            isLoading,
            dataType: DATA_TYPE.RATES,
        };
    }

    private get isDownloadDisabled() {
        const { provider, monthrange } = this.form;

        if (!monthrange || !provider) {
            return true;
        }

        return false;
    }

    private get buttons() {
        const buttons = [
            {
                label: this.$tc('popup.download'),
                onClick: () => this.downloadExcel(),
                isDisabled: this.isDownloadDisabled,
            },
            {
                label: this.$tc('popup.send'),
                onClick: () => this.downloadExcel(true),
                isDisabled: this.isDownloadDisabled,
            },
        ];

        return buttons;
    }

    private get properties() {
        const { month, year } = this.documentFiltersService;
        return [
            {
                label: this.$tc('popup.dateRange'),
                key: 'monthrange',
                component: MonthPicker,
                default: new Date(year, month),
                props: {
                    locale: this.$i18n.locale,
                },
            },
        ];
    }

    private get customColumns() {
        return [
            {
                key: 'change',
                label: this.$tc('titles.change'),
                default: true,
            },
            {
                key: 'score',
                label: this.$tc('titles.score'),
                default: true,
            },
            {
                key: 'diff_delta',
                label: this.$tc('titles.diffnum'),
                default: true,
            },
            {
                key: 'diff_precent',
                label: this.$tc('titles.diffper'),
                default: true,
            },
        ];
    }

    private get filters() {
        const { chainRatesProviders } = this.providersService;
        const providerItems = this.providersService.toItemsList(chainRatesProviders);

        const filters = [
            {
                label: this.$tc('titles.provider'),
                key: 'provider',
                options: providerItems,
                default: this.clusterService.currentRatesProvider,
            },
        ];

        return filters;
    }

    private async downloadExcel(toEmail = false) {
        try {
            this.isLoading = true;
            const { form } = this;

            const monthrange = [
                moment(form.monthrange).format('YYYY-MM-DD'),
                moment(new Date(form.monthrange.getFullYear(), form.monthrange.getMonth() + 1, 0)).format('YYYY-MM-DD'),
            ];

            const columns = Object.entries(form.columns).map(([key, value]) => ({
                name: key,
                value,
            }));

            await this.clusterRatesService.downloadExcel(monthrange, form.provider, columns, toEmail);

            if (toEmail) {
                this.triggerFormMessage(this.$tc('popup.reportSent'));
            } else {
                this.closeForm();
            }
        } catch (_) {
            this.triggerFormError(this.$tc('err.nodata'));
        } finally {
            this.isLoading = false;
        }
    }
}
